import React, { FC, useEffect, useRef, useState } from 'react';
import './inputText.scss';
import { InputGroup } from '../inputGroup/inputGroup';

interface InputTextProps {
  dbField: string;
  label: string;
  value: string;
  placeholder: string;
  reset: boolean;
  validateType?: string | undefined;
  required?: boolean;
  change: (keyField: string | undefined, value: string | undefined) => void;
}

export const InputText: FC<InputTextProps> = ({
  label,
  dbField,
  value,
  placeholder,
  reset,
  change,
  validateType,
  required,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState<boolean>(false);
  const [initDBField, setInitDBField] = useState<string>();
  const [initValue, setInitValue] = useState<string | undefined>(undefined);
  const [initValidateType, setInitValidateType] = useState<string | undefined>(undefined);
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const isEditRef = useRef<HTMLDivElement>(null);
  const editNode = isEditRef.current as any;

  const errorStateRef = useRef<HTMLDivElement>(null);
  const node = errorStateRef.current as any;

  useEffect(() => {
    if (reset) {
      setInitValue(value);
      node?.classList.remove('error');
      node?.classList.remove('good');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (required !== undefined) {
      setIsRequired(required);
    }
  }, [required]);

  useEffect(() => {
    if(value === undefined) {
      setInitValue(undefined);
    } else if(value === null) {
      setInitValue(undefined);
    } else if(value.length === 0) {
      setInitValue(isRequired ? undefined : '');
    } else {
      setInitValue(value);
    }
  }, [value, isRequired]);

  useEffect(() => {
    if (dbField !== undefined) {
      setInitDBField(dbField);
    }
  }, [dbField]);

  useEffect(() => {
    if (edit) {
      editNode?.classList.add('edit');
      editNode.children[1].focus();
      setFocus(true);
    } else {
      setFocus(false);
      editNode?.classList.remove('edit');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (validateType !== undefined) {
      setInitValidateType(validateType);
    }
  }, [validateType]);

  useEffect(() => {
    node?.classList.remove('error');
    node?.classList.remove('good');
    if (!edit) {
      if (initValue !== value) {
        if (initValue) {
          if (initValidateType !== undefined) {
            validate(initValue, initValidateType);
          } else {
            validate(initValue, 'text');
          }
        } else {
          change(initDBField, initValue)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue, edit, initDBField]);

  useEffect(() => {
    if (!hasFocus) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, [hasFocus]);

  const onEnter = (event: any) => {
    if (event.key === 'Enter') {
      setEdit(false);
    }
  };

  const onChange = (event: any) => {
    setInitValue(event.target.value);
  };

  const validate = (value: string, type: string) => {
    if (value.length >= 1) {
      switch (type) {
        case 'text':
          if (validateText(value)) {
            node?.classList.add('good');
            change(initDBField, initValue);
          } else {
            node?.classList.add('error');
            setErrorMessage('Eingetragener Wert ist ungültig.');
          }
          break;
        case 'email':
          if (validateEmail(value)) {
            node?.classList.add('good');
            change(initDBField, initValue);
          } else {
            node?.classList.add('error');
            setErrorMessage('Keine gültige E-Mail Adresse.');
          }
          break;
        case 'phone':
          if (validatePhone(value)) {
            node?.classList.add('good');
            change(initDBField, initValue);
          } else {
            node?.classList.add('error');
            setErrorMessage('Keine gültige Telefonnummer.');
          }
          break;
        case 'address':
          if (validateAddress(value)) {
            node?.classList.add('good');
            change(initDBField, initValue);
          } else {
            node?.classList.add('error');
            setErrorMessage('Keine gültige Adresse');
          }
          break;
        case 'postCode':
          if (validatePostCode(value)) {
            node?.classList.add('good');
            change(initDBField, initValue);
          } else {
            node?.classList.add('error');
            setErrorMessage('Keine gültige Postleitzahl');
          }
          break;
        case 'currency':
          if (validateCurrency(value)) {
            node?.classList.add('good');
            change(initDBField, initValue);
          } else {
            node?.classList.add('error');
            setErrorMessage('Kein gültiger Betrag');
          }
          break;
        default:
          change(initDBField, initValue);
      }
    } else {
      if (isRequired) {
        node?.classList.add('error');
        setErrorMessage('Dieses Feld sollte nicht leer sein.');
      }
    }
  };

  const validateText = (text: string) => {
    const rex = /[0-9a-zA-ZÄÖÜäöüß\s-]{2,}/g;
    return rex.test(text);
  };

  const validateEmail = (email: string) => {
    const rex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rex.test(email);
  };

  const validatePhone = (phone: string) => {
    // eslint-disable-next-line no-useless-escape
    const rex = /^((?:\+\d+)?\s*(?:\(\d+\)\s*(?:[\/–-]\s*)?)?\d+(?:\s*(?:[\s\/–-]\s*)?\d+)*)$/g;
    return rex.test(phone);
  };

  const validateAddress = (address: string) => {
    const rex = /[0-9a-zA-ZÄÖÜäöüß\s-]{2,}/g;
    return rex.test(address);
  };

  const validatePostCode = (postCode: string) => {
    const rex = /^[0-9]{4,5}$/;
    return rex.test(postCode);
  };

  const validateCurrency = (currency: string) => {
    const rex = /^(\d*\.)?\d+$/igm;
    return rex.test(currency);
  };

  return (
    <InputGroup label={label}>
      <div ref={isEditRef} onClick={() => setEdit(true)}>
        <div className="edit-wrapper" ref={errorStateRef}>
          <span className="value">
            {!initValue ? placeholder ? placeholder: '-' : initValue}
          </span>
          <span className="errorLabel">{errorMessage}</span>
        </div>
        <input
          type="text"
          className="inputText"
          value={initValue === undefined ? '' : initValue}
          onChange={onChange}
          onKeyDown={onEnter}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          autoFocus
        />
      </div>
    </InputGroup>
  );
};
