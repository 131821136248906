import React, { useMemo } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { createBrowserHistory } from 'history';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import parseUrl from './shared/parseTenantFromUrl';

export var history = createBrowserHistory();


const Router = ({children}) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(authSelectors.selectCurrentUser);

    const checkCurrentTenantFromUrl = (currentTenantId: string) => {
        try {
            const [urlTenantId, route] = parseUrl(window.location.href)
            if (urlTenantId && urlTenantId !== currentTenantId) {
                dispatch(authActions.doSetTenantById(urlTenantId, route))
                return false;
            }
            return true
        } catch (error) {
            // console.log(error)
            return false
        }
    }

    history = useMemo(() => {
        if (currentUser && currentUser.id) {
            const tenantId = AuthCurrentTenant.get();
            if (checkCurrentTenantFromUrl(tenantId)) {
                dispatch(authActions.doRouteChange(true));
                return createBrowserHistory({ basename: `/tenant/${tenantId}`})
            }
        }
        dispatch(authActions.doRouteChange(false));
        return createBrowserHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])

    return (
        <ConnectedRouter history={history} key={Math.random()}>
            {children}
        </ConnectedRouter>
    )
}

export default Router;
