import { pull } from 'lodash';
import actions from 'src/modules/auth/authActions';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const initialData = {
  currentUser: null,
  backupUser: null,
  currentTenant: null,
  loadingInit: true,
  loadingEmailConfirmation: false,
  loadingPasswordResetEmail: false,
  loadingPasswordChange: false,
  loadingVerifyEmail: false,
  loadingPasswordReset: false,
  loadingUpdateProfile: false,
  loading: false,
  sendingEmail: false,
  errorMessage: null,
  routeChanged: false,
  changingTenant: false,
  toggledSuperAdmin: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if (type === actions.CURRENT_USER_REFRESH_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      currentTenant: AuthCurrentTenant.selectAndSaveOnStorageFor(payload.currentUser),
    };
  }

  if (type === actions.CURRENT_USER_REFRESH_ERROR) {
    return {
      ...state,
      currentUser: null,
      backupUser: null,
      currentTenant: AuthCurrentTenant.selectAndSaveOnStorageFor(null),
    };
  }

  if (type === actions.AUTH_START) {
    return {
      ...state,
      errorMessage: null,
      loading: true,
    };
  }

  if (type === actions.AUTH_SUCCESS && payload) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      backupUser: JSON.parse(JSON.stringify(payload.currentUser || {})),
      currentTenant: AuthCurrentTenant.selectAndSaveOnStorageFor(payload.currentUser),
      errorMessage: null,
      loading: false,
    };
  }

  if (type === actions.AUTH_ERROR) {
    return {
      ...state,
      currentUser: null,
      currentTenant: null,
      errorMessage: payload || null,
      loading: false,
    };
  }

  if (type === actions.EMAIL_CONFIRMATION_START) {
    return {
      ...state,
      loadingEmailConfirmation: true,
    };
  }

  if (type === actions.EMAIL_CONFIRMATION_SUCCESS) {
    return {
      ...state,
      loadingEmailConfirmation: false,
    };
  }

  if (type === actions.EMAIL_CONFIRMATION_ERROR) {
    return {
      ...state,
      loadingEmailConfirmation: false,
    };
  }

  if (type === actions.PASSWORD_RESET_EMAIL_START) {
    return {
      ...state,
      loadingPasswordResetEmail: true,
    };
  }

  if (type === actions.PASSWORD_RESET_EMAIL_SUCCESS) {
    return {
      ...state,
      loadingPasswordResetEmail: false,
    };
  }

  if (type === actions.PASSWORD_RESET_EMAIL_ERROR) {
    return {
      ...state,
      loadingPasswordResetEmail: false,
    };
  }

  if (type === actions.UPDATE_PROFILE_START) {
    return {
      ...state,
      loadingUpdateProfile: true,
    };
  }

  if (type === actions.UPDATE_PROFILE_SUCCESS) {
    return {
      ...state,
      loadingUpdateProfile: false,
    };
  }

  if (type === actions.UPDATE_PROFILE_ERROR) {
    return {
      ...state,
      loadingUpdateProfile: false,
    };
  }

  if (type === actions.PASSWORD_CHANGE_START) {
    return {
      ...state,
      loadingPasswordChange: true,
    };
  }

  if (type === actions.PASSWORD_CHANGE_SUCCESS) {
    return {
      ...state,
      loadingPasswordChange: false,
    };
  }

  if (type === actions.PASSWORD_CHANGE_ERROR) {
    return {
      ...state,
      loadingPasswordChange: false,
    };
  }

  if (type === actions.AUTH_INIT_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      backupUser: JSON.parse(JSON.stringify(payload.currentUser || {})),
      currentTenant: AuthCurrentTenant.selectAndSaveOnStorageFor(payload.currentUser),
      loadingInit: false,
    };
  }

  if (type === actions.AUTH_INIT_ERROR) {
    return {
      ...state,
      currentUser: null,
      currentTenant: null,
      loadingInit: false,
    };
  }

  if (type === actions.EMAIL_VERIFY_START) {
    return {
      ...state,
      loadingVerifyEmail: true,
    };
  }

  if (type === actions.EMAIL_VERIFY_SUCCESS) {
    return {
      ...state,
      loadingVerifyEmail: false,
    };
  }

  if (type === actions.EMAIL_VERIFY_ERROR) {
    return {
      ...state,
      loadingVerifyEmail: false,
    };
  }

  if (type === actions.EMAIL_SEND_START) {
    return {
      ...state,
      sendingEmail: true,
    };
  }

  if (type === actions.EMAIL_SEND_SUCCESS) {
    return {
      ...state,
      sendingEmail: false,
    };
  }

  if (type === actions.EMAIL_SEND_ERROR) {
    return {
      ...state,
      sendingEmail: false,
    };
  }

  if (type === actions.ROUTE_CHANGED) {
    return {
      ...state,
      routeChanged: payload.status,
    };
  }

  if (type === actions.CHANGING_TENANT_START) {
    return {
      ...state,
      changingTenant: true,
    };
  }

  if (type === actions.CHANGING_TENANT_SUCCESS) {
    return {
      ...state,
      changingTenant: false,
    };
  }

  if (type === actions.TOGGLE_SUPER_ADMIN_PERMISSION) {
    let { currentUser, currentTenant } = state
    if (!currentUser) return state;
    if (!currentTenant) return state;

    (currentUser as any).tenants = (currentUser as any).tenants.map((userTenant) => {
      if (userTenant.tenant.id === (currentTenant as any).id) {
        if (payload.status) {
          userTenant.roles = pull(userTenant.roles, 'superadmin')
        }else {
          if (!userTenant.roles.includes('superadmin'))
            userTenant.roles.push('superadmin')
        }
      }
      return userTenant;
    })
    return {
      ...state,
      currentUser: currentUser,
      toggledSuperAdmin: payload.status,
    };
  }


  return state;
};
