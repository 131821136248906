import React, { FC, useEffect, useState } from 'react';
import './inputCalender.scss';
import { InputGroup } from '../inputGroup/inputGroup';
import { DatePicker } from 'antd';
import moment from 'moment'

interface InputCalenderProps {
  dbField: string;
  label: string;
  value: string;
  reset: boolean;
  change: (keyField: string | undefined, value: any | undefined) => void;
}

export const InputCalender: FC<InputCalenderProps> = ({ label, dbField, value, reset, change }) => {
  const [initDBField, setInitDBField] = useState<string>();
  const [initValue, setInitValue] = useState<any>(null);

  useEffect(() => {
    if (reset) {
      setInitValue('');
    }
  }, [reset]);

  useEffect(() => {
    if (value) {
      setInitValue(moment(value));
    } else {
      setInitValue('');
    }
  }, [value]);

  useEffect(() => {
    if (dbField !== undefined) {
      setInitDBField(dbField);
    }
  }, [dbField]);

  useEffect(() => {
    if (value !== initValue) {
      if (initValue !== null) {
        change(initDBField, initValue);
      } else {
        change(initDBField, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  return (
    <InputGroup label={label}>
      <DatePicker
        defaultValue={initValue !== undefined ? initValue : ''}
        value={initValue}
        onChange={(date) => setInitValue(date)}
        showTime={false}
        format={'DD.MM.YYYY'}
      />
    </InputGroup>
  );
};
