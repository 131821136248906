import { Layout, Menu, Switch } from 'antd';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EuroCircleOutlined } from '@ant-design/icons';
import SiderWrapper from 'src/view/layout/styles/SiderWrapper';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';
import { i18n } from 'src/i18n';
import CCLogo from './logo-dark.png';

const { Sider } = Layout;

const AppMenu = (props) => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const backupUser = useSelector(authSelectors.selectBackupUser);
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const toggledSuperAdmin = useSelector(authSelectors.selectToggledSuperAdmin);
  // const logoUrl = useSelector(authSelectors.selectLogoUrl);
  const permissionChecker = new PermissionChecker(currentTenant, currentUser);
  const backupChecker = new PermissionChecker(currentTenant, backupUser);

  const toggleMenuOnResize = () => {
    window.innerWidth < 576 ? hideMenu() : showMenu();
  };

  useEffect(() => {
    toggleMenuOnResize();
    window.addEventListener('resize', toggleMenuOnResize);

    return () => {
      window.removeEventListener('resize', toggleMenuOnResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedKeys = () => {
    const url = props.url;
    const match = menus.find((option) => {
      if (option.exact) {
        return url === option.path;
      }

      return url === option.path || url.startsWith(option.path + '/');
    });
    if (match) {
      return [match.path];
    }
  };

  const hideMenu = () => {
    dispatch(actions.doHideMenu());
  };

  const showMenu = () => {
    dispatch(actions.doShowMenu());
  };

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  const lockedForCurrentPlan = (permission) => {
    return permissionChecker.lockedForCurrentPlan(permission);
  };

  const isSuperAdmin = backupChecker.isSuperAdmin;

  const handleToggleSuperAdmin = (checked) => {
    dispatch(authActions.doToggleSuperAdmin(checked))
  }

  return (
    <SiderWrapper
      style={{
        display: menuVisible ? 'block' : 'none',
      }}
    >
      <Sider theme="light" trigger={null}>
        <div className="logo" style={{ height: 'auto' }}>
          <Link to="/">
            <img src={CCLogo} width="164px" alt={i18n('app.title')} />
          </Link>
        </div>

        <Menu theme="light" mode="inline" selectedKeys={selectedKeys()}>
          {menus
            .filter((menu) => match(menu.permissionRequired))
            .map((menu) => (
              <Menu.Item key={menu.path}>
                <Link to={menu.path}>
                  {menu.icon}
                  <span>{menu.label}</span>
                  {menu.badge || null}
                </Link>
              </Menu.Item>
            ))}

          {menus
            .filter((menu) => lockedForCurrentPlan(menu.permissionRequired))
            .map((menu) => (
              <Menu.Item
                style={{
                  cursor: 'auto',
                  opacity: 0.3,
                }}
                key={menu.path}
              >
                {menu.icon}
                <span>{menu.label}</span>
              </Menu.Item>
            ))}
          <Menu.Item>
            <EuroCircleOutlined />
            <span>
              <a target="_blank" rel="noopener noreferrer" href="https://am-beratung.de/partnerprogramm">
                Partnerprogramm
              </a>
            </span>
          </Menu.Item>
          <Menu.Item>
            <EuroCircleOutlined />
            <span>
              <a target="_blank" rel="noopener noreferrer" href="https://partner-bereich.mymemberspot.de/">
                A&M Mitgliederbereich
              </a>
            </span>
          </Menu.Item>
          {isSuperAdmin ? 
            <Menu.Item>
              Admin View: <Switch size="small" checked={toggledSuperAdmin} onChange={handleToggleSuperAdmin}/>
            </Menu.Item>
          : null}
        </Menu>
      </Sider>
    </SiderWrapper>
  );
};

export default AppMenu;
