import React, { FC, useState } from 'react';
import './print.scss';
import CCLogo from './logo-dark.png';
import { CustomerCard } from '../drawer/cards/customerCard/card';
import { RequestDetailsCard } from '../drawer/cards/requestDetailsCard/card';
import { HistoryCard } from '../drawer/cards/historyCard/card';
import { QuestionnaireCard } from '../drawer/cards/questionnaireCard/card';

import { useParams } from 'react-router-dom';
import LeadService from '../../../modules/lead/leadService';
import { DrawerSpinner } from '../../base/drawer/spinner/spinner';
import { Button, PageHeader } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

export const Print: FC = () => {
  let { id } = useParams();
  const [data, setData] = useState<any | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  if (data === undefined) {
    const fetchData = async () => {
      return await LeadService.find(id);
    };
    fetchData()
      .then((res) => setData(res))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(true));
  }

  const update = () => {
    console.log('Update');
  };

  return isLoading ? (
    <>
      <DrawerSpinner />
    </>
  ) : (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.close()}
        title={'Drucken'}
        subTitle={'Anfrage: ' + data.firstName + ' ' + data.lastName + ' - ' + id}
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => {
              window.print();
              window.close();
            }}
          >
            <PrinterOutlined /> Jetzt drucken
          </Button>,
        ]}
      />

      <div className={'print'}>
        <div className={'header'}>
          <div className={'logo'}>
            <img src={CCLogo} width="164px" alt="logo" />
          </div>
          <h1>
            Anfrage: {data.firstName} {data.lastName}
          </h1>
          <h2>Produkt: {data.product.name}</h2>
        </div>

        <CustomerCard record={data} update={update} reset={false} />
        <RequestDetailsCard record={data} update={update} reset={false} />
        <HistoryCard record={data.dealHistory} />
        <QuestionnaireCard record={data.questionnaire} />
      </div>
    </>
  );
};
