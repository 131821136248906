import React, { FC } from 'react';
import './card.scss';
import { ContactsOutlined, PhoneFilled } from '@ant-design/icons';
import { Card } from '../../../../base/card/card';
import { InputText } from '../../../../base/froms/inputText/inputText';
import { Row } from '../../../../base/layout/row/row';
import { Col } from '../../../../base/layout/col/col';
import { InputSelect } from 'src/components/base/froms/inputSelect/inputSelect';
import { Button } from 'src/components/base/button/button';

interface CustomerCardProps {
  record: any;
  reset: boolean;
  update: (key: string, value: string) => void;
}

export const CustomerCard: FC<CustomerCardProps> = ({ record, reset, update }) => {
  // const [country, setCountry] = useState<any>();
  const countryList = [
    { value: '', label: '' },
    { value: 'de', label: 'Deutschland' },
    { value: 'at', label: 'Österreich' },
    { value: 'ch', label: 'Schweiz' },
    { value: 'li', label: 'Liechtenstein'},
  ];

  const change = (key, date) => {
    console.log(key, date)
    if (key !== undefined) {
      if (date !== undefined) {
        update(key, date);
      }
    }
  };

  return (
    <Card title={'Stammdaten des Kunden'} icon={<ContactsOutlined />}>
      <Row>
        <Col>
          <InputText
            label={'Vorname'}
            dbField="firstName"
            value={record.firstName}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
        <Col>
          <InputText
            label={'Nachname'}
            dbField="lastName"
            value={record.lastName}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Adresse'}
            dbField="street"
            value={record.street}
            placeholder={''}
            change={change}
            reset={reset}
            required={false}
            validateType="address"
          />
        </Col>
        <Col>
          <InputText
            label={'PLZ'}
            dbField="postalCode"
            value={record.postalCode}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="postCode"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Stadt'}
            dbField="city"
            value={record.city}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
        <Col>
          <InputSelect
            label={'Land'}
            dbField="country"
            value={record.country}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {countryList.map((data: any, index: number) => {
              return record.country === data.value ? (
                <option key={index} value={data.value} selected>
                  {data.label}
                </option>
              ) : (
                <option key={index} value={data.value}>
                  {data.label}
                </option>
              );
            })}
          </InputSelect>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Telefon'}
            dbField="phone"
            value={record.phone}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="phone"
          />
        </Col>
        <div className="visibleOnMobile">
          <Col>
            <a href={'tel:' + record.phone }>
              <Button type="primary">
                <PhoneFilled />
                <span> Jetzt anrufen</span>
              </Button>
            </a>
          </Col>
        </div>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'E-Mail'}
            dbField="email"
            value={record.email}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="email"
          />
        </Col>
      </Row>
    </Card>
  );
};
