import React, { FC, useEffect, useState } from 'react';
import './card.scss';
import { ControlOutlined } from '@ant-design/icons';
import { Card } from '../../../../base/card/card';
import { Col } from '../../../../base/layout/col/col';
import { InputText } from '../../../../base/froms/inputText/inputText';
import { Row } from '../../../../base/layout/row/row';
import { InputSelect } from '../../../../base/froms/inputSelect/inputSelect';
import ProductService from '../../../../../modules/product/productService';
import leadEnumerators from '../../../../../modules/lead/leadEnumerators';
import { i18n } from '../../../../../i18n';

import _ from 'lodash';

interface RequestDetailsCardProps {
  record: any;
  reset: boolean;
  update: (key: string, value: string) => void;
}

export const RequestDetailsCard: FC<RequestDetailsCardProps> = ({ record, reset, update }) => {
  const [product, setProduct] = useState<any>([]);
  const [source, setSource] = useState<any>([]);

  useEffect(() => {
    productList().then((res) => {
      setProduct(res);
    });
    setSource(leadEnumerators.source);
  }, [record]);

  const change = (key, date) => {
    if (key !== undefined) {
      if (date !== undefined) {
        update(key, date);
      }
    }
  };

  const productList = async () => {
    const tmp = await ProductService.list('', '', 100, 0);
    return tmp.rows;
  };

  return (
    <Card title={'Details zur Anfrage'} icon={<ControlOutlined />}>
      <Row>
        <Col>
          <InputSelect
            label={'Produkt'}
            dbField="product"
            value={_.hasIn(record.product, 'id') ? record.product.id : '-'}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {
              !_.hasIn(record.product, 'id') ? <option selected></option> : ''
            }
            
            {product.map((data: any, index: number) => {
              if (record.product?.name) {
                return record.product?.name === data.name ? ( 
                  <option key={index} value={data.id} selected>
                    {data.name}
                  </option>
                ) : (
                  <option key={index} value={data.id}>
                    {data.name}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={data.id}>
                    {data.name}
                  </option>
                );
              }
            })}
          </InputSelect>
        </Col>
        <Col>
          <InputText
            label={'Entscheider'}
            dbField="secondDecisionMaker"
            value={record.secondDecisionMaker}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Auftragsvolumen'}
            dbField="budget"
            value={record.budget}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="currency"
          />
        </Col>
        <Col>
          <InputSelect
            label={'Quelle'}
            dbField="source"
            value={record.source !== undefined ? record.source : '-'}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {source.map((value: string, index: number) => {
              return record.source === value ? (
                <option key={index} value={value} selected>
                  {i18n(`entities.lead.enumerators.source.${value}`)}
                </option>
              ) : (
                <option key={index} value={value}>
                  {i18n(`entities.lead.enumerators.source.${value}`)}
                </option>
              );
            })}
          </InputSelect>
        </Col>
      </Row>
    </Card>
  );
};
