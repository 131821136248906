import React, { FC, useEffect, useState } from 'react';
import { Card } from '../../../../base/card/card';
import { HistoryOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import './card.scss';
import { i18n } from '../../../../../i18n';
import { Steps } from 'antd';

interface HistoryCardProps {
  record: any;
}

const { Step } = Steps;

export const HistoryCard: FC<HistoryCardProps> = ({ record }) => {
  const [history, setHistory] = useState<any>([]);

  useEffect(() => {
    if (record !== undefined) {
      record.sort((a,b) => {
        let dateA: any = new Date(a.date);
        let dateB: any = new Date(b.date);
        return dateA - dateB;
      });
      setHistory(record);
    }
  }, [record]);

  return (
    <Card title={'Kontaktverlauf'} icon={<HistoryOutlined />}>
      <Steps direction="vertical">
        {history.slice().reverse().map((item: any, index: string) => {
          const noticeTitle = item.notice ? item.notice : ''
          let oneLine = noticeTitle.split('\n')[0];
          if (oneLine.length > 50) {
            oneLine = oneLine.substring(0, 45) + '...';
          }

          return (
            <Step
              key={index}
              status={'finish'}
              title={item.dealStage === 'new' ? 'Neu' : i18n(`entities.applicant.enumerators.dealStage.${item.dealStage}`)}
              description={
                <div>
                  <span>
                    {item.date !== undefined ? format(Date.parse(item.date.toString()), 'dd.MM.yyyy HH:mm') : ''}
                  </span>
                  <span>{item.createdBy !== undefined ? item.createdBy : ''}</span>
                  <p style={{ color: 'black' }} title={noticeTitle}>
                    <strong>{oneLine}</strong>
                  </p>
                </div>
              }
            />
          );
        })}
      </Steps>
    </Card>
  );
};
