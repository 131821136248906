import React, { FC, useEffect, useState } from 'react';
import './card.scss';
import { ControlOutlined } from '@ant-design/icons';
import { Card } from '../../../../base/card/card';
import { Col } from '../../../../base/layout/col/col';
import { InputText } from '../../../../base/froms/inputText/inputText';
import { Row } from '../../../../base/layout/row/row';
import { InputSelect } from '../../../../base/froms/inputSelect/inputSelect';
import JobService from '../../../../../modules/job/jobService';
import applicantEnumerators from '../../../../../modules/applicant/applicantEnumerators';
import { i18n } from '../../../../../i18n';

import _ from 'lodash';

interface RequestDetailsCardProps {
  record: any;
  reset: boolean;
  update: (key: string, value: string) => void;
}

export const RequestDetailsCard: FC<RequestDetailsCardProps> = ({ record, reset, update }) => {
  const [job, setJob] = useState<any>([]);
  const [source, setSource] = useState<any>([]);

  useEffect(() => {
    jobList().then((res) => {
      setJob(res);
    });
    setSource(applicantEnumerators.source);
  }, [record]);

  const change = (key, date) => {
    if (key !== undefined) {
      if (date !== undefined) {
        update(key, date);
      }
    }
  };

  const jobList = async () => {
    const tmp = await JobService.list('', '', 100, 0);
    return tmp.rows;
  };

  return (
    <Card title={'Details zur Bewerbung'} icon={<ControlOutlined />}>
      <Row>
        <Col>
          <InputSelect
            label={'Stelle'}
            dbField="job"
            value={_.hasIn(record.job, 'id') ? record.job.id : '-'}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {
              !_.hasIn(record.job, 'id') ? <option selected></option> : ''
            }
            
            {job.map((data: any, index: number) => {
              if (record.job?.title) {
                return record.job?.title === data.title ? ( 
                  <option key={index} value={data.id} selected>
                    {data.title}
                  </option>
                ) : (
                  <option key={index} value={data.id}>
                    {data.title}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={data.id}>
                    {data.title}
                  </option>
                );
              }
            })}
          </InputSelect>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Gehaltsvorstellungen'}
            dbField="salaryExpectations"
            value={record.salaryExpectations}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="currency"
          />
        </Col>
        <Col>
          <InputSelect
            label={'Quelle'}
            dbField="source"
            value={record.source !== undefined ? record.source : '-'}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {source.map((value: string, index: number) => {
              return record.source === value ? (
                <option key={index} value={value} selected>
                  {i18n(`entities.applicant.enumerators.source.${value}`)}
                </option>
              ) : (
                <option key={index} value={value}>
                  {i18n(`entities.applicant.enumerators.source.${value}`)}
                </option>
              );
            })}
          </InputSelect>
        </Col>
      </Row>
    </Card>
  );
};
