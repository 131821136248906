import React, { FC, ReactChild, ReactChildren } from 'react';
import './card.scss';

interface CardProps {
  icon?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  title: string;
  headerNav?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const Card: FC<CardProps> = ({ icon, title, headerNav, children }) => {
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-icon">{icon}</div>
        <div className="card-title">{title}</div>
        <div className="card-navbar">{headerNav}</div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};
