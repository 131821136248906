import React, { FC } from 'react';
import './card.scss';
import { ContactsOutlined, PhoneFilled } from '@ant-design/icons';
import { Card } from '../../../../base/card/card';
import { InputText } from '../../../../base/froms/inputText/inputText';
import { Row } from '../../../../base/layout/row/row';
import { Col } from '../../../../base/layout/col/col';
import { InputSelect } from 'src/components/base/froms/inputSelect/inputSelect';
import { Button } from 'src/components/base/button/button';
import { InputCalender } from 'src/components/base/froms/inputCalender/inputCalender';

interface CustomerCardProps {
  record: any;
  reset: boolean;
  update: (key: string, value: string) => void;
}

export const CustomerCard: FC<CustomerCardProps> = ({ record, reset, update }) => {
  // const [country, setCountry] = useState<any>();

  const sexList = [
    { value: '', label: '' },
    { value: 'male', label: 'Mann' },
    { value: 'female', label: 'Frau' },
    { value: 'divers', label: 'Diverse' },
  ];

  const change = (key, date) => {
    if (key !== undefined) {
      if (date !== undefined) {
        update(key, date);
      }
    }
  };

  return (
    <Card title={'Stammdaten des Bewerbers'} icon={<ContactsOutlined />}>
      <Row>
        <Col>
          <InputText
            label={'Vorname'}
            dbField="firstName"
            value={record.firstName}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
        <Col>
          <InputText
            label={'Nachname'}
            dbField="lastName"
            value={record.lastName}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'E-Mail'}
            dbField="email"
            value={record.email}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="email"
          />
        </Col>
        <Col>
          <InputSelect
            label={'Geschlecht'}
            dbField="sex"
            value={record.sex}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {sexList.map((data: any, index: number) => {
              return record.sex === data.value ? (
                <option key={index} value={data.value} selected>
                  {data.label}
                </option>
              ) : (
                <option key={index} value={data.value}>
                  {data.label}
                </option>
              );
            })}
          </InputSelect>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Telefon'}
            dbField="phone"
            value={record.phone}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="phone"
          />
        </Col>
        <div className="visibleOnMobile">
          <Col>
            <a href={'tel:' + record.phone }>
              <Button type="primary">
                <PhoneFilled />
                <span> Jetzt anrufen</span>
              </Button>
            </a>
          </Col>
        </div>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Postleitzahl'}
            dbField="zipCode"
            value={record.zipCode}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
        <Col>
          <InputCalender
            label={'Geburtsdatum'}
            dbField="dateOfBirth"
            value={record.dateOfBirth}
            change={change}
            reset={reset}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Qualifikation'}
            dbField="qualification"
            value={record.qualification}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
        <Col>
          <InputText
            label={'Anmerkungen'}
            dbField="notes"
            value={record.notes}
            change={change}
            reset={reset}
            placeholder={''}
            validateType="text"
          />
        </Col>
      </Row>
    </Card>
  );
};
