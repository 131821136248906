import React, { useState } from 'react';
import { configureStore } from 'src/modules/store';
import deDE from 'antd/lib/locale/de_DE';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import { ConfigProvider } from 'antd';
// import { getAntdLanguage } from 'src/i18n';
import { LoadScript } from '@react-google-maps/api';
import { CCProvider } from './context/ContactCloudContext';
import { IntercomProvider } from 'react-use-intercom';
import { WoopraTracker } from './components/thirdParty/woopra/woopra';
import Router from 'src/view/router';
// import { Action } from 'redux';
const INTERCOM_APP_ID = 'k5t3mava';

const GOOGLE_API_KEY = 'AIzaSyD8d6p67hBWoVr2qkq8vIKpXmkmk-2ibO0';
const store = configureStore();

const App = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  
  const onUnreadCountChange = (amount: number) => {
    setUnreadMessagesCount(amount);
  };


  return (
    <WoopraTracker config={{domain: 'app.contact-cloud.io'}}>
      <CCProvider>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        onUnreadCountChange={onUnreadCountChange}
      >
        <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
          <ConfigProvider locale={deDE}>
            <Provider store={store}>
              <Router>
                <RoutesComponent />
              </Router>
            </Provider>
          </ConfigProvider>
        </LoadScript>
      </IntercomProvider>
    </CCProvider>
    </WoopraTracker>
  );
};

export default App;
