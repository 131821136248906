import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantCreate: {
        id: 'tenantCreate',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantRead: {
        id: 'tenantRead',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantDashboard: {
        id: 'query',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantDetach: {
        id: 'tenantDetach',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userAssign: {
        id: 'userAssign',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userReadInvitationToken: {
        id: 'userReadInvitationToken',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.settingsBackgroundImages, storage.settingsLogos],
      },
      leadImport: {
        id: 'leadImport',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      leadCreate: {
        id: 'leadCreate',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      leadEdit: {
        id: 'leadEdit',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      leadDestroy: {
        id: 'leadDestroy',
        // allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      leadRead: {
        id: 'leadRead',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      leadReadDeletedItems: {
        id: 'leadReadDeletedItems',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      leadAutocomplete: {
        id: 'leadAutocomplete',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      applicantImport: {
        id: 'applicantImport',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      applicantCreate: {
        id: 'applicantCreate',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      applicantEdit: {
        id: 'applicantEdit',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      applicantDestroy: {
        id: 'applicantDestroy',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      applicantRead: {
        id: 'applicantRead',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      applicantReadDeletedItems: {
        id: 'applicantReadDeletedItems',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      applicantAutocomplete: {
        id: 'applicantAutocomplete',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      productImport: {
        id: 'productImport',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productCreate: {
        id: 'productCreate',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      productEdit: {
        id: 'productEdit',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      productDestroy: {
        id: 'productDestroy',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      productRead: {
        id: 'productRead',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productAutocomplete: {
        id: 'productAutocomplete',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      jobImport: {
        id: 'jobImport',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jobCreate: {
        id: 'jobCreate',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      jobEdit: {
        id: 'jobEdit',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      jobDestroy: {
        id: 'jobDestroy',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      jobRead: {
        id: 'jobRead',
        allowedRoles: [roles.superadmin, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jobAutocomplete: {
        id: 'jobAutocomplete',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      supplierImport: {
        id: 'supplierImport',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      supplierCreate: {
        id: 'supplierCreate',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      supplierEdit: {
        id: 'supplierEdit',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      supplierDestroy: {
        id: 'supplierDestroy',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      supplierRead: {
        id: 'supplierRead',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      supplierAutocomplete: {
        id: 'supplierAutocomplete',
        allowedRoles: [roles.superadmin, roles.admin, roles.customer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      campaignRead: {
        id: 'supplierAutocomplete',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sheetCreate: {
        id: 'sheetCreate',
        allowedRoles: [roles.superadmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sheetEdit: {
          id: 'sheetEdit',
          allowedRoles: [roles.superadmin],
          allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sheetDestroy: {
          id: 'sheetDestroy',
          allowedRoles: [roles.superadmin],
          allowedPlans: [plans.free, plans.growth, plans.enterprise],
          allowedStorage: [],
      },
      sheetRead: {
          id: 'sheetRead',
          allowedRoles: [roles.superadmin],
          allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
